<template>
  <h1>Estoy en reportes</h1>
</template>

<script>
export default {
  name: 'Reports'
}
</script>

<style scoped>

</style>
